import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Project from '../views/Project.vue'
import Notifications from '../views/Notifications.vue'
import store from "../store";

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    document.title = to?.meta?.title ?? "АК-2025";
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    document.title = to?.meta?.title ?? "АК-2025";
    next();
    return;
  }
  next("/login");
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: "АК-2025"},
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/projects/:projectId',
    props: true,
    meta: {title: "Проекты"},
    name: "ViewProject",
    component: Project,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/notifications',
    props: true,
    meta: {title: "Уведомления"},
    name: "Notifications",
    component: Notifications,
    beforeEnter: ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
