<template>
	<div class="pure-g hours-picker">
		<div class="pure-u-14-24">
			<input type="number" min="0" step="0.5" autocomplete="off" :disabled="disabled" v-model="editingValue" @input="refreshValue()">
			{{ editingValue}}
			{{editingType}}
		</div>
		<div class="pure-u-10-24">
			<select v-model="editingType" @change="typeChanged">
				<option value="0">часов</option>
				<option value="1">дней</option>
			</select>
		</div>
	</div>
</template>

<style scoped>
.hours-picker input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
.hours-picker select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    
	height: 100%;
    padding-left: .3em;
    padding-right: .3em;
}
</style>

<script>
export default {
	name: 'HoursPicker',
	props: {
		'value': Number,  // всегда в часах
		'disabled': {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			editingValue: this.value,
			editingType: 0
		}
	},
	watch: {
		value: function(newVal) {
			this.editingValue = this.editingType == 1 ? newVal/8 : newVal
		}
	},
	mounted() {
		console.log(this.value)
		if (this.value >= 16) {
			this.editingType = 1
			this.editingValue = Math.ceil(this.value/8*2)/2
			this.typeChanged()
		}
  
	},
	methods: {
		typeChanged() {
			// if (this.editingType == 1) { // дни
			// 	this.editingValue /= 8
			// 	// округляем вверх до 0.5
			// 	this.editingValue = Math.ceil(this.editingValue*2)/2
			// } else { // часы
			// 	this.editingValue *= 8
			// }
			this.refreshValue()
		},
		refreshValue() {
			if (this.editingType == 1) { // дни
				this.$emit('input', this.editingValue*8)
			} else {
				this.$emit('input', this.editingValue)
			}
		}
	}
}
</script>