<template>
  <modal name="PopupAboutProfile" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :width="450" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Данные о пользователе</span>
      <button class="btn" @click="$modal.hide($options.name); $modal.show('PopupEditProfile', userId)" v-if="isManageAllowed"><i class="fal fa-pencil"></i></button>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    
    <div class="content padding" @submit.prevent="onSubmit">
      <div class="txt-c padding-b margin-b">
        <avatar class="avatar" side="150px" :userid="userId" :url="user.avatarUrl" font-size="3rem" />
      </div>
      <div class="pure-g container contacts">
        <strong class="pure-u-1-3 title" v-if="user && user.id">ФИО:</strong>
        <div class="pure-u-2-3">
          {{ user.lastName }}
          {{ user.firstName }}
          {{ user.middleName }}
        </div>

        <strong class="pure-u-1-3 title" v-if="user && user.jobTitle">Должность:</strong>
        <div class="pure-u-2-3" v-if="user && user.jobTitle">
          {{ user.jobTitle }}
        </div>

        <strong class="pure-u-1-3 title" v-if="false && role">Роль:</strong>
        <div class="pure-u-2-3" v-if="false && role">
          {{ role.name }}
        </div>

        <strong class="pure-u-1-3 title" v-if="false && user.specialization">Отдел:</strong>
        <div class="pure-u-2-3" v-if="false && user.specialization">
          {{ user.specialization.name }}
        </div>

        <strong class="pure-u-1-3 title" v-if="false && user.role">Специализация:</strong>
        <div class="pure-u-2-3" v-if="false && user.role">
          {{ user.role.name }}
        </div>

        <div class="pure-u-1 margin-t"></div>

        <strong class="pure-u-1-3 title" v-if="user.email">Email:</strong>
        <div class="pure-u-2-3" v-if="user.email">
          <a :href="'mailto:' + user.email"><i class="fal fa-at"></i> {{ user.email }}</a>
        </div>

        <strong class="pure-u-1-3 title" v-if="user.phone">Телефон:</strong>
        <div class="pure-u-2-3" v-if="user.phone">
          <a :href="'tel:' + user.phone"><i class="fal fa-phone-alt"></i> {{ user.phone }}</a>
        </div>

        <strong class="pure-u-1-3 title" v-if="user.whatsapp">WhatsApp:</strong>
        <div class="pure-u-2-3" v-if="user.whatsapp">
          <a target="_blank" :href="'https://api.whatsapp.com/send?phone=' + user.whatsapp">{{ user.whatsapp }}</a>
        </div>
      </div>
    </div>
  </modal>
</template>

<style scoped>
.contacts strong {
  font-size: .8rem;
  text-align: right;
}

.contacts strong,
.contacts strong + div {
  margin-bottom: .5rem;
}

.contacts a, .contacts a:active, .contacts a:visited {
  color: #005eaf;
  text-decoration: none;
}
.contacts a:hover {
  text-decoration: underline;
  color: #004886;
}

.contacts i {
  display: inline-block;
  width: 1.3rem;
}
</style>

<script>
import { mapGetters } from "vuex";
import Avatar from '@/components/Avatar.vue';

import { MANAGE_USERS } from "@/utils/permissions"

export default {
  name: 'PopupAboutProfile',
  components: {
    Avatar
  },
  data() {
    return {
      userId: null,
      user: {}
    }
  },
  computed: {
    ...mapGetters(["allUsersKeyed", "allRoles", "authRoles"]),
    isManageAllowed() {
      return this.authRoles.indexOf(MANAGE_USERS) !== -1
    },
    role() {
      if (!this.user || !this.user.roleId) {
        return null
      }
      return this.allRoles.find(r => r.id == this.user.roleId)
    }
  },
  methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.userId = data.params
      this.$set(this, 'user', this.allUsersKeyed[this.userId])
      console.log(this.user)
      this.$escStack.push(this.$options.name)
    }
	}
}
</script>