<template>
  <div class="project" v-if="activeProject.id" style="min-height: 20rem">
    <Keypress key-event="keyup" :key-code="27" @success="stopSelecting(); closeChildren()" />

    <popup-task-subscribers />
    <popup-download-group />
    <popup-edit-gallery />
    <popup-edit-gallery-image />
    <popup-edit-task-group />
    <popup-edit-task-theme />
    <popup-about-task />
    <popup-working-files />
    <popup-all-stages />
    <popup-add-stage />
    <popup-about-profile />

    <popup-edit-task-cell />
    <popup-edit-task />
    <popup-file-preview />
    
    <slideout-panel></slideout-panel>

    <vue-element-loading :active="isLoadingProject"/>

    <button class="pull-right btn hidden" style="align-self: flex-end"  @click="onAddTab"><i class="fal fa-plus"></i> Добавить</button>
    <vue-tabs v-model="activeTabName" v-if="!isLoadingProject" @tab-change="onTabChanged" v-bind:class="{
        'has-chat-notifications': notifications.chatProjects[projectId],
        'has-notifications': notifications.projects[projectId],
      }">
      <v-tab title="1 " icon="fas fa-exclamation-circle">
        <vuescroll :ref="'notifications_tab'">
          <notifications class="padding-v" ref="notifications"/>
        </vuescroll>
      </v-tab>

      <v-tab title="2 " icon="fas fa-bell">
        <h2 class="margin-t no-margin-b">Все задачи</h2>
        <filters-picker class="padding-v" v-model="tabFilters[0]" v-on:input="onFiltersChanged(0)" />
        <vuescroll :ref="'tableScroll_0'">
        <tasks-table v-if="activeGroup.name" :group="activeGroup" :root="activeGroup" :show-empty-groups="false" :subdir-opened="true" :nesting="0"/>
        </vuescroll>
      </v-tab>

      <v-tab v-for="(tab, index) in activeGroup.groups" :key="tab.id" :title="'tab' + tab.id">
        <div slot="title">
          {{ tab.name || 'Без имени' }}

          <span v-if="tabNotifications[tab.id]" class="notification">
              <span v-if="tabNotifications[tab.id] < 10">
                  {{ tabNotifications[tab.id] }}
              </span>
              <span v-else><i class="fas fa-exclamation"></i></span>
          </span>
          <span v-if="notifications.chatGroups[tab.id] && notifications.chatGroups[tab.id].length" class="notification chat"><i class="fas fa-comments"></i></span>

          <span @click.prevent="onEditTab(tab)" class="hidden action-icon animated fal fa-cog padding-half-l"></span>
        </div>
        
        <filters-picker class="padding-v" v-model="tabFilters[index+1]" v-on:input="onFiltersChanged(index+1)" />
        <div class="scroll-wrp">
          <vuescroll :ref="'tableScroll_' + tab.id">
            <tasks-table :group="tab" :root="tab" :nesting="0" :subdir-opened="true" :show-empty-groups="true" />
          </vuescroll>
        </div>
      </v-tab>

      <v-tab title="Галерея" icon="fas fa-images">
        <gallery :project-id="projectId" ref="gallery" />
      </v-tab>
    </vue-tabs>
  </div>
</template>

<style>
:not(.vm--modal) .scroll-wrp, .vue-tabs, .tab-content, .tab-container {
  
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.vue-tabs, .tab-content {
  position: relative;
  border-top: 1px solid #ddd;
  top: -1px;
}
.project .vue-tabs .nav-tabs {
  border: none;
}

:not(.vm--modal) .vue-tabs .nav-tabs {
  overflow: hidden;
}
:not(.vm--modal) .vue-tabs .nav-tabs li {
  margin-right: .5em;
}
:not(.vm--modal) .vue-tabs .nav.nav-tabs .tab .tabs__link {
  position: relative;
  text-decoration: none;
  background-color: #FFF;
  border: 1px solid #CCC;
  display: inline-block;
  position: relative;
  min-width: 6.5em;
  text-align: center;
  color: #9d9fa1;
  transition: all .2s;
  border-bottom: 1px solid transparent;
  margin-right: .1em;
  z-index: 0;
  padding-top: .3em;
  padding-bottom: .3em;
  border-top-left-radius: .4em;
  border-top-right-radius: .4em;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
}
.project > .vue-tabs .nav.nav-tabs li.tab:first-of-type .tabs__link,
.project > .vue-tabs .nav.nav-tabs li.tab:nth-of-type(2) .tabs__link {
  min-width: 0;
  padding: 0 0 1px 0;
  margin: 0;
}
.project .vue-tabs .nav.nav-tabs li.tab:first-of-type .tabs__link .title,
.project .vue-tabs .nav.nav-tabs li.tab:nth-of-type(2) .tabs__link .title {
  display: inline-block;
  padding: .1em .5em .4em 1em;
  text-indent: -0.5em;
  color: transparent;
 }

.project > .vue-tabs .nav.nav-tabs li.tab:first-of-type .tabs__link i,
.project > .vue-tabs .nav.nav-tabs li.tab:nth-of-type(2) .tabs__link i {
  color: #9d9fa1;
  left: .6em;
  padding-left: .7em;
  padding-right: .7em;
}

.project > .vue-tabs.has-chat-notifications .nav.nav-tabs li.tab:first-of-type .tabs__link i {
    color: #ff9900;
}
.project > .vue-tabs.has-notifications .nav.nav-tabs li.tab:first-of-type .tabs__link i {
    color: #E40231;
}

.project > .vue-tabs .nav.nav-tabs li.tab:first-of-type .tabs__link.active_tab i,
.project > .vue-tabs .nav.nav-tabs li.tab:nth-of-type(2) .tabs__link.active_tab i {
  color: #FFF;
}

.project > .vue-tabs .nav.nav-tabs li.tab:first-of-type .tabs__link i + *,
.project > .vue-tabs .nav.nav-tabs li.tab:nth-of-type(2) .tabs__link i + * {
  font-size: 0;
}

:not(.vm--modal) .vue-tabs .nav.nav-tabs .tab .tabs__link .title > i {
  position: relative;
  top: .2em;
  left: -.2em;
}
:not(.vm--modal) .vue-tabs .nav.nav-tabs .tab .tabs__link .notification {
  position: relative;
  left: .33em;
  border: 2px solid #FFF;
  float: right;
}
:not(.vm--modal) .vue-tabs .nav.nav-tabs .tab .tabs__link.active_tab {
  font-weight: 700;
  background-color: #509E2F;
  color: #FFF;
  border-color: #509E2F;
  z-index: 1;
}
</style>

<style scoped>
.project {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

.nav-tabs .action-icon {
  margin: -1em -1em -1em -.5em;
  padding: 1em .5em 1em 1em;
  cursor: pointer;
  opacity: .25;
}
.nav-tabs .action-icon:hover {
  opacity: 1;
}

.notification {
    display: inline-block;
    text-align: center;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: .75rem;
    margin-right: .2rem;
    font-weight: bold;
    border: none;
    background-color: #E40231;
    color: #FFF;
    border-radius: 50%;
}
.notification.chat {
  background-color: #ff9900;
}
</style>

<script>
import VueElementLoading from 'vue-element-loading'
import vuescroll from 'vuescroll';
import {VueTabs, VTab} from 'vue-nav-tabs'
import Keypress from 'vue-keypress'

import PopupAboutProfile from '@/components/PopupAboutProfile.vue'
import PopupDownloadGroup from '@/components/PopupDownloadGroup.vue'
import PopupEditGallery from '@/components/PopupEditGallery.vue'
import PopupEditGalleryImage from '@/components/PopupEditGalleryImage.vue';
import PopupEditTaskGroup from '@/components/PopupEditTaskGroup.vue'
import PopupEditTaskTheme from '@/components/PopupEditTaskTheme.vue'
import PopupAboutTask from '@/components/PopupAboutTask.vue'
import PopupAddStage from '@/components/PopupAddStage.vue'
import PopupWorkingFiles from '@/components/PopupWorkingFiles.vue'
import PopupAllStages from '@/components/PopupAllStages.vue'
import PopupFilePreview from '@/components/PopupFilePreview.vue'
import PopupTaskSubscribers from '@/components/PopupTaskSubscribers.vue'

import PopupEditTaskCell from '@/components/tasksTable/PopupEditTaskCell.vue'
import PopupEditTask from '@/components/tasksTable/PopupEditTask.vue'

import FiltersPicker from '@/components/FiltersPicker.vue'
import TasksTable from '@/components/tasksTable/TasksTable.vue'
import Gallery from '@/components/Gallery.vue';

import Notifications from '@/views/Notifications.vue';

import TaskGroup from '@/models/taskGroup'
import { mapGetters } from "vuex"
import { PERMISSIONS_REQUEST } from "@/store/actions/auth"
import { PROJECT_REQUEST, STOP_SELECTING, CELLS_SELECTION_STOP, TASK_ADDED, TASK_UPDATED, FILTERS_UPDATED, TASK_CHILDREN_OPEN, TASK_CHILDREN_CLOSE } from "@/store/actions/project"
import { USERS_REQUEST, USER_UPDATE } from "@/store/actions/users"
import { ROLES_REQUEST } from "@/store/actions/roles"
import { NOTIFICATIONS_REQUEST } from "@/store/actions/notifications"

export default {
  name: 'Project',
  props: ['projectId'],
  components: {
    VueElementLoading,
    vuescroll,
    VueTabs,
    VTab,
    Keypress,
    PopupAboutProfile,
    PopupDownloadGroup,
    PopupEditGallery,
    PopupEditGalleryImage,
    PopupEditTaskGroup,
    PopupAboutTask,
    PopupAddStage,
    PopupWorkingFiles,
    PopupAllStages,
    PopupFilePreview,
    PopupEditTaskTheme,
    PopupTaskSubscribers,
    PopupEditTaskCell,
    PopupEditTask,
    FiltersPicker,
    TasksTable,
    Gallery,
    Notifications
  },
  data() {
    return {
      isTabsLoading: false,
      editTaskContextVisible: false,
      activeTabName: "", //"tab1",
      tabs: [],
      filters: [],
      tabFilters: [],
    }
  },
  computed: {
    ...mapGetters(["authId", "isLoadingProject", "activeProject", "activeGroup", "notifications", "taskShowing", "groupsKeyed", "tasksKeyed"]),
    tabNotifications() {
      let counts = {}
      for (var tab of this.activeGroup.groups) {
        counts[tab.id] = this.groupNotificationsRecoursive(tab)
      }
      return counts
    }
  },
  mounted() {
    this.onProjectSelected()

    this.$sodnpHub.$on('task-added', this.onTaskAdded)
    this.$sodnpHub.$on('task-updated', this.onTaskUpdated)

    this.$sodnpHub.$on('user-updated', this.onUserUpdated)

    this.$store.dispatch(PERMISSIONS_REQUEST)
  },
  beforeDestroy: function() {
    this.$sodnpHub.$off('task-added', this.onTaskAdded)
    this.$sodnpHub.$off('task-updated', this.onTaskUpdated)

    this.$sodnpHub.$off('user-updated', this.onUserUpdated)
  },
  watch: {
    projectId() {
      this.onProjectSelected()
    },
    activeProject() {
      document.title = this.activeProject.name
    },
    activeGroup() {
      // устанавливаем дефолтные фильтры для каждой из вкладок
      this.tabFilters.splice(0, this.tabFilters.length)
      // ЦУ
      this.$set(this.tabFilters, 0, [
        {
          type: 'highlight_mine',
          enabled: false
        },
        {
          type: 'attention',
          enabled: true
        },
        {
          type: 'status',
          value: [],
          enabled: false
        },
        {
          type: 'assigned',
          value: [],
          search_option: 'one_in_cell',
          enabled: false
        }
      ])
      for (var i=1; i<=this.activeGroup.groups.length; i++) {
        this.$set(this.tabFilters, i, [
          {
            type: 'highlight_mine',
            enabled: false
          },
          {
            type: 'attention',
            enabled: false
          },
          {
            type: 'status',
            value: [],
            enabled: false
          },
          {
            type: 'assigned',
            value: [],
            search_option: 'one_in_cell',
            enabled: false
          }
        ])
      }
      this.onTabChanged(0)
    },
    taskShowing(task) {
      if (!task || !task.id) {
        return
      }

      let path = [{
        "type": "task",
        "item": task
      }]
      // вычисляем путь до этой задачи
      var nextItem = task
      if (!task.parentTaskId && !task.groupId) {  // нераспределенная задача
        path.push({
            "type": "group",
            "item": this.groupsKeyed[0]
          })
        this.activeTabName = "2 "
      }
      while (nextItem) {
        if (nextItem.parentTaskId) {
          nextItem = this.tasksKeyed[nextItem.parentTaskId]
          path.push({
            "type": "task",
            "item": nextItem
          })
        } else if (nextItem.parentGroupId) {
          nextItem = this.groupsKeyed[nextItem.parentGroupId]
          path.push({
            "type": "group",
            "item": nextItem
          })
        } else if (nextItem.groupId) {
          nextItem = this.groupsKeyed[nextItem.groupId]
          path.push({
            "type": "group",
            "item": nextItem
          })
        } else {
          nextItem = null
        }
      }
      path.reverse()
      // path[0] - это вкладка 
      if (this.activeGroup.id != path[0].item.id) {
        this.selectTab(path[0].item.id)
      }
      this.$nextTick(() => {
        /*for(var i=0; i<this.filters.length; i++) {
          this.filters[i].enabled = false
        }
        this.$set(this, 'filters', this.filters)
        */
        const taskFound = path.find(p => p.type == "task")
        if (taskFound && (task.groupId || taskFound.parentTaskId)) {
          this.$store.dispatch(TASK_CHILDREN_OPEN, taskFound.item.id)
        }
        this.$nextTick(() => {
          this.$refs['tableScroll_' + path[0].item.id][0].scrollIntoView("#task_row_" + task.id, 0);
        })
      })
    }
  },
  methods: {
    onProjectSelected() {
      this.activeTabName = "2 "
      this.stopSelecting()
      this.$store.dispatch(ROLES_REQUEST)
      this.$store.dispatch(PROJECT_REQUEST, { "projectId": this.projectId })
      this.$store.dispatch(USERS_REQUEST)
    },
    onAddTab() {
      this.$modal.show('PopupEditTaskGroup', Object.assign(new TaskGroup, {
        color: '#f1f2f3',
        projectId: this.projectId,
        parentGroupId: null,
        parentTaskId: null, // тут всегда null и не null только когда будем добавлять из задачи
        managerIds: [],
        columns: []
      }))
    },
    onEditTab(tab) {
      this.$modal.show('PopupEditTaskGroup', tab)
    },
    onTabChanged(index) {
      if (index == 0) {  // уведомления
        this.$nextTick(() => {
          this.$refs.notifications.loadNotifications()
        })
      }
      if (index > this.tabFilters.length) { // галерея открыта
        console.log('Gallery opened')
        this.$refs.gallery.loadGalleries()
        return
      }
      this.$store.dispatch(TASK_CHILDREN_CLOSE)
      // index-1 because there is first norifications tab
      this.$store.dispatch(FILTERS_UPDATED, this.tabFilters[index-1])
    },
    onFiltersChanged(index) {
      this.$store.dispatch(FILTERS_UPDATED, this.tabFilters[index])
    },

    selectTab(groupId) {
      if (typeof groupId === "number") {
       this.activeTabName = 'tab' + groupId
      } else {
        this.activeTabName = groupId
      }
    },
    stopSelecting() {
      this.$store.dispatch(STOP_SELECTING)
      this.$store.dispatch(CELLS_SELECTION_STOP)
    },
    closeChildren() {
      this.$store.dispatch(TASK_CHILDREN_CLOSE)
    },
    groupNotificationsRecoursive(group) {
      let found = 0
      if (this.notifications.groups[group.id]) {
        found += this.notifications.groups[group.id].length
      }
      for (var g of group.groups) {
        found += this.groupNotificationsRecoursive(g)
      }
      return found
    },


    onTaskUpdated(data) {
      this.$store.dispatch(NOTIFICATIONS_REQUEST)
      this.$store.dispatch(TASK_UPDATED, data)
    },
    onTaskAdded(data) {
      this.$store.dispatch(TASK_ADDED, data)
      this.$store.dispatch(NOTIFICATIONS_REQUEST)
    },

    onUserUpdated(data) {
      this.$store.dispatch(USER_UPDATE, data.profileId)
    },
  }
}
</script>
